import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndianCurrency } from './currency.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { DateAndTimePipe } from './date-and-time.pipe';


@NgModule({
    declarations: [CustomDatePipe, DateAndTimePipe, IndianCurrency],
    exports: [CustomDatePipe, DateAndTimePipe, IndianCurrency],
    imports: [
        CommonModule
    ]
})
export class PipeModule { }
