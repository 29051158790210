import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { CustomValidators } from '../validators/custom.validator';
export function   validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      CustomValidators.safeValue(control);
      control.markAsTouched({
        onlySelf: true
      });
      control.markAsDirty({
        onlySelf: true
      });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    } else if (control instanceof FormArray) {
      control.controls.forEach((abstractControl) => {
      const formGroup = abstractControl as FormGroup
      validateAllFormFields(formGroup);
      });
    }
  });

}
