<div class="container">
    <div class="row">
        <div class="col-8">
            <h1 class="bold font-24 py-4">{{'coupon.list.title' | translate}}</h1>

        </div>
        <div class="col-4 text-end ">
            <button type="button" (click)="onClickAddCoupon()" class="outline-0 font-22  border-0 px-4 py-1 mt-4 orangeBG text-white height-33">Add Coupon</button>
        </div>
    </div>

    <div class="pb-5">
        <div class="px-3 tableShadow bg-white">

            <div class="row font-12 light text-dark tableHead py-2">
                <div class="col  maxWidth-70">
                    <div class="py-1 text-center">{{'coupon.list.serialNumber' | translate}}</div>
                </div>
                <div class="col">
                    <div class="py-1 text-center">{{'coupon.list.expiryDate' | translate}}</div>
                </div>
                <div class="col">
                    <div class="py-1 text-center">{{'coupon.list.couponType' | translate}}</div>
                </div>
                <div class="col">
                    <div class="py-1 text-center"></div>
                </div>
                <div class="col">
                    <div class="py-1 text-center"></div>
                </div>
            </div>

            <div class="row font-12 regular tableDiv py-2" *ngFor="let coupon of coupons; let i = index">
                <div class="col text-truncate maxWidth-70">
                    <div class="py-2 text-truncate text-center">{{i+1}}</div>
                </div>
                <div class="col text-truncate">
                    <div class="py-2  text-truncate text-center">
                        {{coupon.expireOn | customDate}}
                    </div>
                </div>
                <div class="col text-truncate">
                    <div class="py-2 text-truncate text-center">{{coupon.couponType}}
                    </div>
                </div>
                <div class="col py-2 text-truncate text-center">
                    <button class="bg-transparent px-2 border-0 border-radius-3 viewIcon" (click)="couponView(coupon.couponId)">
                        <img alt="{{'listingVendor.imgAltView' | translate}}" title="Details"
                            src="../../../assets/images/eye.svg"></button>
                </div>
                <div class="col py-2 text-truncate text-center">
                    <button class="bg-transparent px-2 border-0 border-radius-3 viewIcon" (click)="couponEdit(coupon.couponId)">
                        <img alt="Edit" title="Edit" src="../../../assets/images/edit.svg"></button>
                </div>
            </div>
            <div class="text-center w-100 py-4">
                <pagination [totalItems]="totalElements" previousText="&laquo;" nextText="&raquo;"
                    [itemsPerPage]="pageSize" [maxSize]="pager" (pageChanged)="pageChanged($event)"></pagination>
            </div>

        </div>
    </div>
</div>