<div *ngIf="coupon" class="container">
    <div>
        <div class="row">
            <div class="col-3 col-lg-2 col-md-4 col-sm-4">
               <p class="float-left font-16 medium"> Coupon Type</p>
            </div>
            <div class="col-1">
              <span class="bold">:</span>  
            </div>
            <div class="col-6 col-lg-3 col-md-6 col-sm-6 font-16 medium">
                {{coupon.couponType}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 col-lg-2 col-md-4 col-sm-4">
              <p class="float-left font-16 medium">  {{'coupon.expiryDate.name' | translate}}</p>
            </div>
            <div class="col-1">
                <span class="bold">:</span>  
            </div>
            <div class="col-6 col-lg-3 col-md-6 col-sm-6 font-16 medium">
              <p>  {{coupon.expireOn | customDate}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-3 col-lg-2 col-md-4 col-sm-4">
             <p class="float-left font-16 medium">   {{'coupon.minimumOrder.name' | translate}}</p>
            </div>
            <div class="col-1">
                <span class="bold">:</span>  
            </div>
            <div class="col-6 col-lg-3 col-md-6 col-sm-6 font-16 medium">
                {{coupon.minAmountToApply}}
            </div>
        </div>

        <div>
            <div class="row">
                <div class="col-3 col-lg-2 col-md-4 col-sm-4">
                  <p class="float-left font-16 medium">  Discount</p>
                </div>
                <div class="col-1">
                    <span class="bold">:</span>  
                </div>
                <div class="col-6 col-lg-3 col-md-6 col-sm-6 font-16 medium">
                    <div>
                        <div *ngIf="coupon.discountDetails.discountPercentage">
                            {{coupon.discountDetails.discountPercentage}} %
                        </div>
                        <div *ngIf="coupon.discountDetails.directDiscount">
                            {{coupon.discountDetails.directDiscount}} Flat
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="row">
            <div class="col-3 col-lg-2 col-md-4 col-sm-4">
             <p class="float-left font-16 medium">   {{'coupon.couponCode.name' | translate}}</p>
            </div>
            <div class="col-1">
                <span class="bold">:</span>  
            </div>
            <div class="col-6 col-lg-3 col-md-6 col-sm-6 font-16 medium">
                {{coupon.couponCode}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 col-lg-2 col-md-4 col-sm-4">
             <p class="float-left font-16 medium">  {{'coupon.couponUsage.name' | translate}}</p>
            </div>
            <div class="col-1">
                <span class="bold">:</span>  
            </div>
            <div class="col-6 col-lg-3 col-md-6 col-sm-6 font-16 medium">
                {{coupon.numberOfTimes}}
            </div>
        </div>

        <div class="row py-4">
            <div class="col-4 text-right">
            <button type="button" class="outline-0 font-22  border-0 px-4 py-1 bg-dark text-white " (click)="onClickCancel()">Cancel</button>
        </div>

        </div>

    </div>
</div>