import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  LoaderService
} from './loader/loader.service';
import {
  MessageService
} from './message/message.service';
import {
  throwError as observableThrowError,
  Observable
} from 'rxjs';
import {
  map, catchError
} from 'rxjs/operators';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  options!: {};
  blobOutput = false;
  constructor(private http: HttpClient, private loaderService: LoaderService, private messagingService: MessageService) {
    }

  /**
   * GET method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [blobOutput] - enable/disable output stringify
   * @param [html] - add headers for html
   * @returns `Observable stream` of the api response
   */
  get(url: string, param: any, disableLoader = false, blobOutput?: boolean, html?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');
    }
    this.blobOutput = blobOutput ? blobOutput : false;
    let responseType;
    if (!disableLoader) {
      this.showLoader(true);
    }
    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    if (this.blobOutput) {
      headers = headers.set('Accept', 'application/octet-stream');
      responseType = 'blob';
    } else {
      headers = headers.delete('Accept');
      responseType = 'json';
    }
    this.options = {
      headers,
      params: queryParams,
      responseType,
      withCredentials: true
    };
    return this.http
      .get(url, this.options).pipe(
        map((data: any) =>
          this.handleResponse(data, disableLoader)),
        catchError((error) => {
          this.handleError(error, disableLoader);
          return observableThrowError(error);
        }), );
  }

  /**
   * POST method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @param [blobOutput] - enable/disable output stringify
   * @returns `Observable stream` of the api response
   */
  post(url: string, param: {}, disableLoader = false, upload?: boolean, blobOutput?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');
    }
    let responseType = 'json';
    this.blobOutput = blobOutput ? blobOutput : false;
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      headers = headers.append('enctype', 'multipart/form-data');
    } else {
      headers = headers.append('Content-Type', 'application/json');
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.showLoader(true);
    }
    if (this.blobOutput) {
      responseType = 'blob';
    }
    this.options = {
      headers,
      responseType,
      withCredentials: true
    };

    return this.http.post(url, body, this.options).pipe(
      map((data: any) =>
        this.handleResponse(data, disableLoader)),
      catchError((error) => {
        this.handleError(error, disableLoader);
        return observableThrowError(error);
      }), );
  }
  /**
   * PUT method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @returns `Observable stream` of the api response
   */
  put(url: string, param: {},  disableLoader = false , upload ?: boolean): Observable < {} > {
    let headers = new HttpHeaders();

    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token')) {
      headers = headers.append('access_token', localStorage.getItem('access_token') as string);
    }
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      headers = headers.append('enctype', 'multipart/form-data');
    } else {
      headers = headers.append('Content-Type', 'application/json');
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.showLoader(true);
    }

    this.options = {
      headers,
      withCredentials: true
    };

    return this.http.put(url, body, this.options).pipe(
      map((data: any) =>
        this.handleResponse(data, disableLoader)),
      catchError((error) => {
        this.handleError(error, disableLoader);
        return observableThrowError(error);
      }), );
  }
  /**
   * DELETE method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @returns  `Observable stream` of the api response
   */
  delete(url: string, param: any,  disableLoader = false ): Observable < {} > {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    if (localStorage.getItem('access_token') != null) {
      headers = headers.append('access_token', localStorage.getItem('access_token') || '{}');
    }
    if (!disableLoader) {
      this.showLoader(true);
    }

    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    this.options = {
      headers,
      params: queryParams,
      withCredentials: true
    };

    return this.http
      .delete(url, this.options).pipe(
        map((data: any) =>
          this.handleResponse(data, disableLoader)),
        catchError((error) => {
          this.handleError(error, disableLoader);
          return observableThrowError(error);
        }), );

  }
  /**
   * OAUTHLOGIN method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @returns  `Observable stream` of the api response
   */
   oauthLogin(url: string, param:any,  disableLoader = false , isRefresh ?: boolean): Observable < {} > {
    if (!disableLoader) {
      this.showLoader(true);
    }
    const clientName = Environment.clientName;
    const clientPassword = Environment.clientPassword;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ` + btoa(`${clientName}:${clientPassword}`),
    });

    let bodyz = '';
    if (!isRefresh) {
      const body = new HttpParams()
        .set('username', param.username)
        .set('password', param.password)
        .set('grant_type', 'password');
      bodyz = body.toString();
    } else {
      const body = new HttpParams()
        .set('grant_type', 'refresh_token');
      bodyz = body.toString();
    }

    this.options = {
      headers,
      withCredentials: true
    };
    return this.http.post(url, bodyz, this.options).pipe(
      map((data: any) =>
        this.handleOauthResponse(data, disableLoader)),
      catchError((error) =>
        this.handleOauthError(error, disableLoader))
    );

  }
  /**
   * POST method implementation in common rest service only for EMBRIDGE calls
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @param [blobOutput] - enable/disable output stringify
   * @returns `Observable stream` of the api response
   */
  emBridgePost(url: string, param: {}, disableLoader = false, upload?: boolean, blobOutput?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    let responseType = 'json';
    this.blobOutput = blobOutput ? blobOutput : false;
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      headers = headers.append('enctype', 'multipart/form-data');
    } else {
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.showLoader(true);
    }
    if (this.blobOutput) {
      responseType = 'blob';
    }
    this.options = {
      headers,
      responseType,
      withCredentials: false
    };

    return this.http.post(url, body, this.options).pipe(
      map((data: any) => {
        if (!disableLoader) {
          this.showLoader(false);
        }
        if (data.errorCode || data.errorMessage){
        this.messagingService.notify('error', `Error: ${data.errorCode}`, data.errorMessage);
        return observableThrowError(data.errorCode);
        }
        return data;
      }),
      catchError((error) => {
        this.handleError(error, disableLoader);
        return observableThrowError(error);
      }), );
  }

  /**
   *
   * OAUTHLOGOUT method implementation in common rest service
   * @param url - get url
   * @param  param - url params
   * @param  [disableLoader] - boolean
   * @param  [blobOutput] - boolean
   * @param  [html] - enable/disable parse
   * @returns  - subscribe for reponse
   */
  oauthLogout(url: string, param: any, disableLoader = false, blobOutput?: boolean, html?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    this.blobOutput = blobOutput ? blobOutput : false;
    let responseType;
    if (!disableLoader) {
      this.showLoader(true);
    }
    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    if (this.blobOutput) {
      headers = headers.set('Accept', 'application/octet-stream');
      responseType = 'blob';
    } else {
      headers = headers.delete('Accept');
      responseType = 'json';
    }
    this.options = {
      headers,
      params: queryParams,
      responseType,
      withCredentials: true
    };
    return this.http
      .get(url, this.options).pipe(
        map((data: any) =>
          this.handleOauthResponse(data, disableLoader)),
        catchError((error) =>
         this.handleOauthLogoutError(error, disableLoader)
        ));
  }
  private handleOauthResponse(res: Response, disableLoader:any) {
    if (!disableLoader) {
      this.showLoader(false);
    }
    const body: Response = res;
    return body || {};
  }
  private handleOauthError = (error:any, disableLoader:any) => {
    if (!disableLoader) {
      this.showLoader(false);
    }
    if (error.error instanceof ErrorEvent) {
      console.log('Client-side error occured.');
      this.messagingService.notify('error', `Error: ${error.code}`, error.statusText);
    } else {
      console.log('Server-side error occured.');
      this.messagingService.notify('error', `Error: ${error.status}`, error.error.error_description);
    }

    return observableThrowError(error.error);
  }
  private handleOauthLogoutError = (error:any, disableLoader:any) => {
    if (!disableLoader) {
      this.showLoader(false);
    }
    if (error.error instanceof ErrorEvent) {
      console.log('Client-side error occured.');
    } else {
      console.log('Server-side error occured.');
    }

    return observableThrowError(error.error);
  }
  private handleResponse(res: Response, disableLoader:any) {
   
    
    if (!disableLoader) {
      this.showLoader(false);
    }
    // tslint:disable-next-line: no-any
    const body: any = res;
    let message;
    if (body.status && 'message' in body.status) {
      message = body.status?.message;
    }
    if (body.status && body.status?.code !== 200) {
      this.messagingService.notify('error', `Error: ${body.status?.code}`, message);
    } else
    if (body.status && body.status?.message != null ||  body.status?.message == 0) {     
      this.messagingService.notify('success', 'Success', message);
    }
    return body || {};
  }
  private handleError = (error:any, disableLoader:any) => {
    if (!disableLoader) {
      this.showLoader(false);
    }
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.messagingService.notify('error', `Error: 999`, errorMessage);
    } else {
      // server-side error
      if (error.status) {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        this.messagingService.notify('error', `Error: ${error.status}`, `${error.message}`);
      }
    }
    return observableThrowError(errorMessage);
  }
  getText(url: string, param:any, disableLoader = false): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    let responseType;
    if (!disableLoader) {
      this.showLoader(true);
    }
    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    responseType = 'text';
    this.options = {
      headers,
      params: queryParams,
      responseType,
      withCredentials: true
    };
    return this.http
      .get(url, this.options).pipe(
        map((data: any) => this.handleOauthResponse(data, disableLoader)),
        catchError((error) => {
          this.handleError(error, disableLoader);
          return observableThrowError(error);
        }), );
  }
showLoader(enable:any) {
  if (enable) {
    this.loaderService.show();
  } else {
    this.loaderService.hide();
  }
}
}
