import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../validators/custom.validator';
import { validateAllFormFields } from '../utils/validateform';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm!: FormGroup;
  email: any;
   constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.setPasswordForm = this.formBuilder.group({
      emailId: [{value:'', disabled: true}, Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(20), Validators.minLength(5)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.maxLength(20), Validators.minLength(5)])]
      });
    this.email = this.route.snapshot.queryParamMap.get('email');
    if (this.email) {
      this.setPasswordForm.controls?.['emailId'].setValue(this.email);
    }

}
onSubmit() {
   if (this.setPasswordForm.valid) {
     const data = {
       password: this.setPasswordForm.value.password,
       confirmPassword: this.setPasswordForm.value.confirmPassword
     };
     this.authService.onSettingPassword(data, this.email).subscribe((res: any) => {
    this.router.navigate(['/login']);
    });
  } else {

  validateAllFormFields(this.setPasswordForm);
}
}
}
