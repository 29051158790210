import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageComponent } from './core/message/message.component';
import { LoaderComponent } from './core/loader/loader.component';
import { SharedModule } from './shared/shared.module';
import { SharedServiceModule } from './services/sharedservice.module';
import { MenuModule } from './menu/menu.module';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/http.interceptor';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NoContentComponent } from './nocontent/nocontent.component';
import { SetPasswordComponent } from './set-password/set-password.component';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CouponModule } from './coupon/coupon.module';
import { PipeModule } from './pipes/pipe.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SetPasswordComponent,
    LoginComponent,
    LoaderComponent,
    MessageComponent,
    NoContentComponent,
    ChangePasswordComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      toastClass: 'ngx-toastr customToast'
    }),
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    SharedServiceModule.forRoot(),
    HttpClientModule,
    MenuModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    AppRoutingModule,
    MatAutocompleteModule,
    CouponModule,
    PipeModule,
    FormsModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
