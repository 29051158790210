<div class="container">
    <div class="font-18 pb-3 text-dark">Admin</div>
    <div class="row">
        <div class="col-3">
            <div class="font-12 py-1">Filter-1</div>
            <div><input type="text" placeholder="Filter with" class="inputBox w-100 px-2"></div>
        </div>
        <div class="col-3">
            <div class="font-12 py-1">Filter-2</div>
            <div><input type="text" placeholder="Filter with" class="inputBox w-100 px-2"></div>
        </div>
        <div class="col-3">
            <div class="font-12 py-1">Filter-3</div>
            <div><select class="inputBox w-100 px-2">
                    <option selected disabled value="">select</option>
                    <option value="1">1</option>
                </select></div>
        </div>
        <div class="col-3 text-right">
            <img src="../../assets/img/excel.svg" class="mt-4 excelIco" title="Download Excel">
        </div>
    </div>
    <div class="py-4">
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>