<div class="example-card loginBG">
    <div class="container">
        <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
                <div class="margin-top-btm-30percentage loginBox">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="example-form w-100">
                        <table class="example-full-width w-100" cellspacing="0">
                            <tr>
                                <td class="whiteFont font-24 bold py-3">
                                    <u> Change Password </u>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="example-full-width w-100">
                                        <label class="labelStyle whiteFont font-12">Old Password</label>
                                        <input pInputText name="oldPassword" type="password"
                                            formControlName="OldPassword"
                                            class="form-control borderRadius-30 w-100 my-1 px-3 loginInput whiteFont font-14">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="example-full-width w-100">
                                        <label class="labelStyle whiteFont font-12">New Password</label>
                                        <input pInputText name="newPassword" type="password"
                                            formControlName="newPassword"
                                            class="form-control borderRadius-30 w-100 my-1 px-3 loginInput whiteFont font-14">
                                        <div class="error "
                                            *ngIf="loginForm.controls['newPassword'].invalid &&loginForm.controls['newPassword'].dirty">
                                            <span *ngIf="loginForm.controls['newPassword'].errors?.['required']">Please
                                                enter the password.<br></span>
                                            <span
                                                *ngIf="!loginForm.controls['newPassword'].errors?.['required'] && loginForm.controls['newPassword'].errors?.['minlength']">Password
                                                needs to be at least 5
                                                characters long.<br></span>
                                            <span
                                                *ngIf="!loginForm.controls['newPassword'].errors?.['required'] && loginForm.controls['newPassword'].errors?.['maxlength']">Password
                                                is too long.<br></span>
                                            <span
                                                *ngIf="!loginForm.controls['newPassword'].errors?.['required'] && !loginForm.controls['newPassword'].errors?.['minlength'] && !loginForm.controls['newPassword'].errors?.['maxlength'] && loginForm.controls['newPassword'].errors?.['oneDigitValidator']">It
                                                should contain one number.<br></span>
                                            <span
                                                *ngIf="!loginForm.controls['newPassword'].errors?.['required'] &&  !loginForm.controls['newPassword'].errors?.['minlength'] && !loginForm.controls['newPassword'].errors?.['maxlength'] &&loginForm.controls['newPassword'].errors?.['specialChar']">It
                                                should contain one special character.<br></span>
                                            <span
                                                *ngIf="!loginForm.controls['newPassword'].errors?.['required'] && !loginForm.controls['newPassword'].errors?.['minlength'] && !loginForm.controls['newPassword'].errors?.['maxlength'] &&loginForm.controls['newPassword'].errors?.['uppercase']">It
                                                should contain one uppercase character.<br></span>
                                        </div>
                                    </div>
                                    <div class="example-full-width w-100">
                                        <label class="labelStyle whiteFont font-12">Confirm Password</label>
                                        <input pInputText name="confirmPassword" type="password" maxlength="30"
                                            formControlName="ConfirmPassword"
                                            class="form-control borderRadius-30 w-100 my-1 px-3 loginInput whiteFont font-14">
                                    </div>
                                </td>
                            </tr>
                            <div>
                                <button mat-raised-button type="submit"
                                    class="w-100 my-4 p-2 loginBtn borderRadius-30 whiteFont font-14">Submit</button>
                            </div>
                        </table>
                    </form>

                </div>
            </div>
            <div class="col-4"></div>
        </div>
    </div>
</div>