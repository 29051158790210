import { DataService } from './../../services/data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: []
})
export class MenuComponent implements OnInit {

  classApplied = true;
  welcomeMessage: string='';
  pageUrl: any;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  constructor(private router: Router, public auth: AuthService) {
  }


  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pageUrl = event.url;
        this.pageUrl = this.pageUrl.split('?', 1)[0];
      }
    });
  }

  onClickLogout() {
    this.auth.forceLogout();
    this.router.navigate(['/login']);
  }

  checkPageUrl(value:any) {
    return String(this.pageUrl).includes(value) ? true : '';
  }
}
