import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnInit , OnDestroy {
    show = false;
    private subscription:  Subscription = new Subscription();
    private requestCount = 0;
    constructor(
        private loaderService: LoaderService
    ) {
      this.requestCount = 0;

     }

    ngOnInit() {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
              const self = this;
              setTimeout(() => {
                if (state.show) {
                  self.show = state.show;
                  self.requestCount++;
                } else {
                  self.requestCount--;
                  if (self.requestCount <= 0) {
                    self.show = state.show;
                  }
                }
              }, 0);
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
