import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICoupon } from 'src/app/Interfaces/coupon.interface';
import { IRestResponse } from 'src/app/Interfaces/rest-response.interface';
import { CouponService } from 'src/app/services/coupon.service';

@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.scss']
})
export class CouponDetailComponent implements OnInit {
  couponId: string ='';
  coupon: ICoupon = {} as ICoupon;

  constructor(
    private route: ActivatedRoute,
    private couponService: CouponService,
    private router: Router
  ) {
    this.couponId = this.route.snapshot.queryParamMap.get('id') || '';
  }

  ngOnInit(): void {
    this.getCouponDetails();
  }
  getCouponDetails() {
    if (!this.couponId) {
      return;
    }

    this.couponService.getCoupon(this.couponId).subscribe((res: IRestResponse<ICoupon>) => {
      if (res.response) {
        this.coupon = res.response; 
      }
      
    });
  }

  onClickCancel() {
    this.router.navigate(['/coupon/list']);
  }

}
