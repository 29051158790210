import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { sha256 } from 'js-sha256';
import { UserService } from './../services/user.service';
import { AuthService } from '../services/auth.service';
import { CustomValidators } from '../validators/custom.validator';
import { validateAllFormFields } from '../utils/validateform';
import { SharedServiceModule, DataService } from '../services';
import { Match } from '../validators/match.validator';
import { kMaxLength } from 'buffer';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public forgotPasswordForm!: FormGroup;
  public resetPasswordForm!: FormGroup;
  passwordModalRef!: BsModalRef;
  token:any;
  message:any;
  showMessage: boolean = false;

  config = {
    animated: true,
    ignoreBackdropClick: false,
    class: 'forgotPasswordModal'
  };
  constructor(
    private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private sharedService: SharedServiceModule,
    private modalService: BsModalService, private dataService: DataService
  ) {
    if (localStorage.getItem('user_type')) {
      this.router.navigate(['/listing']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [ Validators.required,CustomValidators.email]],
      password: ['', Validators.compose([Validators.required])],
    });
    this.forgotPasswordForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, CustomValidators.email]],
    });

    this.resetPasswordForm = this.formBuilder.group({
      otp: ['', Validators.compose([Validators.required, Validators.maxLength(6), CustomValidators.onlyNumber()])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(5)
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(5)
      ])]
    },
      {
        validator: Match('password', 'confirmPassword')
      },
    );
  }

  onClickLogin() {
    if (this.loginForm.valid) {
      const data = {
        username: this.loginForm.value.email,
        password: sha256(this.loginForm.value.password),
        grant_type: 'password'
      };

      this.authService.login(data).subscribe((res: any) => {
        this.dataService.setUserDetails(res.response);
        this.sharedService.nextLoginMessage('true');
        this.router.navigate(['/listing']);
      });
    } else {
      validateAllFormFields(this.loginForm);
    }
  }

  openForgotPasswordModel(forgotPasswordTemplate: TemplateRef<any>) {
    this.passwordModalRef = this.modalService.show(forgotPasswordTemplate, this.config);
  }

  onClickResetPassword(resetPasswordTemplate:any) {
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value.emailAddress).subscribe(res => {
        this.token = res.response.token;
        if (res.status.code === 200) {
          this.passwordModalRef.hide();
          this.passwordModalRef = this.modalService.show(resetPasswordTemplate, this.config);
        }
      });
    } else {
      validateAllFormFields(this.forgotPasswordForm);
    }
  }

  resetForm() {
    this.passwordModalRef.hide();
    this.forgotPasswordForm.reset();
    this.resetPasswordForm.reset();
    this.showMessage = false;
  }


  onPasswordReset() {
    const data = {
      token: this.token,
      otp: this.resetPasswordForm.value.otp,
      password: this.resetPasswordForm.value.password,
      confirmPassword: this.resetPasswordForm.value.confirmPassword
    };
    if (this.resetPasswordForm.valid) {
      this.authService.resetPassword(data).subscribe((res:any) => {
        this.showMessage = true;
        this.message = res.status?.message;
        this.resetPasswordForm.reset();
        this.forgotPasswordForm.reset();
      });
    } else {
      validateAllFormFields(this.resetPasswordForm);
    }
  }

  clearEmailField() {
    this.passwordModalRef.hide();
    this.forgotPasswordForm.reset();
  }

}

