import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { ICoupon } from 'src/app/Interfaces/coupon.interface';
import { IPaginationResponse } from 'src/app/Interfaces/pageable-rest-response.interface';
import { IRestResponse } from 'src/app/Interfaces/rest-response.interface';
import { CouponService } from 'src/app/services/coupon.service';
import { CustomValidators } from 'src/app/validators/custom.validator';
import { validateAllFormFields } from '../../utils/validateform';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {

  couponForm!: FormGroup;
  dateCustomClasses!: DatepickerDateCustomClasses[];
  couponId: string;
  coupon: ICoupon = {} as ICoupon;

  constructor(
    private formBuilder: FormBuilder,
    private couponService: CouponService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.couponId = this.route.snapshot.queryParamMap.get('id') || '';
  }

  ngOnInit(): void {
    this.dateCustomClasses = [
      { date: new Date(), classes: ['text-warning'] }];
    this.initCouponForm();
    this.checkCouponExist();
  }


  initCouponForm() {
    this.couponForm = this.formBuilder.group({
      couponId: this.formBuilder.control(''),
      couponType: this.formBuilder.control('MULTIPLE'),
      toDate: this.formBuilder.control('', Validators.required),
      minAmountToApply: this.formBuilder.control('', Validators.required),
      discountDetails: this.formBuilder.group({
        directDiscount: this.formBuilder.control('', [Validators.required, CustomValidators.onlyNumber()]),
        discountPercentage: this.formBuilder.control(''),
        discountType: this.formBuilder.control('flat')
      }),
      couponCode: this.formBuilder.control('', Validators.required),
      numberOfTimes: this.formBuilder.control('', Validators.required),
    });

    this.formValueChanges();
  }

  formValueChanges() {
    this.couponForm.get('discountDetails')?.get('discountType')?.valueChanges.subscribe((value) => {
      if (value === 'flat') {
        this.couponForm.get('discountDetails')?.get('directDiscount')?.setValidators([Validators.required, CustomValidators.onlyNumber()]);
        this.couponForm.get('discountDetails')?.get('discountPercentage')?.clearValidators();
        this.couponForm.get('discountDetails')?.get('discountPercentage')?.reset();
      } else {
        this.couponForm.get('discountDetails')?.get('discountPercentage')?.setValidators([Validators.required, CustomValidators.percentageValidator]);
        this.couponForm.get('discountDetails')?.get('directDiscount')?.clearValidators();
        this.couponForm.get('discountDetails')?.get('directDiscount')?.reset();
      }
      this.couponForm.get('discountDetails')?.get('directDiscount')?.updateValueAndValidity();
      this.couponForm.get('discountDetails')?.get('discountPercentage')?.updateValueAndValidity();
    });
  }

  onClickCancel() {
    this.router.navigate(['/coupon/list']);
  }

  onSubmit() {
    if (!this.couponForm.valid) {
      validateAllFormFields(this.couponForm);
      return;
    }
 
    const data = Object.assign({}, this.couponForm.value);

    data.toDate = this.datePipe.transform(this.couponForm.value.toDate, "dd/MM/yyyy");
    delete data.discountDetails.discountType;
   
    if (this.couponId) { 
      this.couponService.editCoupon(this.couponId, data).subscribe((res: IRestResponse<IPaginationResponse<any>>) => {
       if (res.status) {
        if (res.status.code === 200) {
          this.router.navigate(['/coupon/list']);
        }
       }
        
      });
    } else {

      this.couponService.addCoupon(data).subscribe((res: IRestResponse<IPaginationResponse<any>>) => {
        if (res.status) {
        if (res.status.code === 200) {
          this.router.navigate(['/coupon/list']);
        }
      }
      });
    }

  }

  checkCouponExist() {
    if (this.couponId) {

      this.couponService.getCoupon(this.couponId).subscribe((res: IRestResponse<ICoupon>) => {
        if (res.response) {
          this.coupon = res.response;
          this.patchCouponForm();
        }
       
      });

    }
  }
  patchCouponForm() {
    this.couponForm.patchValue({
      couponId: this.coupon.couponId,
      couponType: this.coupon.couponType,
      toDate: new Date(this.coupon.expireOn),
      minAmountToApply: this.coupon.minAmountToApply,
      discountDetails: this.coupon.discountDetails,
      couponCode: this.coupon.couponCode,
      numberOfTimes: this.coupon.numberOfTimes,
    });

    if (this.coupon.discountDetails.directDiscount) {
      this.couponForm.get('discountDetails')?.patchValue({
        discountType: 'flat',
        directDiscount: this.coupon.discountDetails.directDiscount
      });
    } else {
      this.couponForm.get('discountDetails')?.patchValue({
        discountType: 'percentage',
        discountPercentage: this.coupon.discountDetails.discountPercentage
      })
    }
  }

  get isFlatDiscount(): boolean {
    return this.couponForm.get('discountDetails')?.get('discountType')?.value === 'flat' ? true : false;
  }

}
