import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IListingType } from '../Interfaces/listingType.interfce';

@Injectable()
export class DataService {

  private dataChangeEvent = new Subject<any>();
  private categoryChangeEvent = new Subject<any>();
  typeChangeForBannerEvent = new Subject<any>();

  listingTypes:IListingType[] = [
    {

      listingName: 'Property', listingValue: 'Property',
      subType: [
        { subTypeName: 'Villa', subTypeValue: 'VILLA' },
        { subTypeName: 'Home Stay', subTypeValue: 'HOME_STAY' },
        { subTypeName: 'Apartment', subTypeValue: 'APARTMENT' },
        { subTypeName: 'Resorts', subTypeValue: 'RESORTS' },
        { subTypeName: 'Hotel', subTypeValue: 'HOTEL' },
        { subTypeName: 'Farm Stay', subTypeValue: 'FARM_STAY' },
        { subTypeName: 'Beach House', subTypeValue: 'BEACH_HOUSE' },
        { subTypeName: 'Farm Lofts', subTypeValue: 'FARM_LOFTS' },
        { subTypeName: 'Hostel', subTypeValue: 'HOSTEL' },
        { subTypeName: 'Private Room', subTypeValue: 'PRIVATE_ROOM' },
        { subTypeName: 'Co-working', subTypeValue: 'CO_WORKING' },
        { subTypeName: 'Private Garden', subTypeValue: 'PRIVATE_GARDEN' },
        { subTypeName: 'Function Hall', subTypeValue: 'FUNCTION_HALL' },
        { subTypeName: 'Cruise', subTypeValue: 'CRUISE' },
        { subTypeName: 'Spa', subTypeValue: 'SPA' },
        { subTypeName: 'Creche', subTypeValue: 'CRECHE' },
        { subTypeName: 'Camping Site', subTypeValue: 'CAMPING_SITE' },
        { subTypeName: 'Forest', subTypeValue: 'FOREST' },
        { subTypeName: 'River Side', subTypeValue: 'RIVER_SIDE' },
        { subTypeName: 'Park', subTypeValue: 'PARK' },
        { subTypeName: 'Beach', subTypeValue: 'Beach' },

      ],
      category: [
        { categoryName: 'Luxury Stays', categoryValue: 'LUXURY' },
        { categoryName: 'Budget Stays', categoryValue: 'BUDGET' },
        { categoryName: 'Hourly Stays', categoryValue: 'HOURLY' },
        { categoryName: 'Day Picnic', categoryValue: 'DAY_PICNIC' },
        { categoryName: 'Special Function', categoryValue: 'SPECIAL_FUNCTION' }
      ]
    },
    {
      listingName: 'Food And Beverages', listingValue: 'FOOD_AND_BEVERAGES',
      subType: [
        { subTypeName: 'Restaurant', subTypeValue: 'RESTAURANT' },
        { subTypeName: 'Home Kitchen', subTypeValue: 'HOME_KITCHEN' },
        { subTypeName: 'Cafe', subTypeValue: 'CAFE' },
        { subTypeName: 'Bar', subTypeValue: 'BAR' },
        { subTypeName: 'Shop', subTypeValue: 'SHOP' },
      ],
      category: []
    },
    {
      listingName: 'Experience Provider', listingValue: 'EXPERIENCE_PROVIDER',
      subType: [
        { subTypeName: 'Beauty & Spa', subTypeValue: 'BEAUTY_AND_SPA' },
        { subTypeName: 'Music & Dance', subTypeValue: 'MUSIC_AND_DANCE' },
        { subTypeName: 'Hobby Classes', subTypeValue: 'HOBBY_CLASSES' },
        { subTypeName: 'Yoga & Wellness', subTypeValue: 'YOGA_AND_WELLNESS' },
        { subTypeName: 'Virtual Experience', subTypeValue: 'VIRTUAL_EXPERIENCE' },
        { subTypeName: 'Guided Tour', subTypeValue: 'GUIDED_TOUR' },
        { subTypeName: 'Package Tour', subTypeValue: 'PACKAGE_TOUR' },
        { subTypeName: 'Tour & Cruises', subTypeValue: 'TOUR_AND_CRUISES' },
        { subTypeName: 'Camping', subTypeValue: 'CAMPING' },
        { subTypeName: 'Trekking', subTypeValue: 'TREKKING' },
        { subTypeName: 'Water Sports', subTypeValue: 'WATER_SPORTS' },
        { subTypeName: 'Adventure Sports', subTypeValue: 'ADVENTURE_SPORTS' },
        { subTypeName: 'Biking', subTypeValue: 'BIKING' },
        { subTypeName: 'Boating', subTypeValue: 'BOATING' },
        { subTypeName: 'Jungle Safari', subTypeValue: 'JUNGLE_SAFARI' },

      ],
      category: [

      ]
    },
    {
      listingName: 'Service Provider', listingValue: 'SERVICE_PROVIDER',
      subType: [
        { subTypeName: 'Transport', subTypeValue: 'TRANSPORT' },
        { subTypeName: 'Driver', subTypeValue: 'DRIVER' },
        { subTypeName: 'Music & Dj', subTypeValue: 'MUSIC_AND_DJ' },
        { subTypeName: 'Beautician', subTypeValue: 'BEAUTICIAN' },
        { subTypeName: 'Home Tutor', subTypeValue: 'HOME_TUTOR' },
        { subTypeName: 'Online Tutor', subTypeValue: 'ONLINE_TUTOR' },
        { subTypeName: 'Nurses & Attenders', subTypeValue: 'NURSES_AND_ATTENDERS' },
        { subTypeName: 'Online Doctor', subTypeValue: 'ONLINE_DOCTOR' },
        { subTypeName: 'Creche', subTypeValue: 'CRECHE' },
        { subTypeName: 'Cook', subTypeValue: 'COOK' },
        { subTypeName: 'Vet', subTypeValue: 'VET' },
        { subTypeName: 'Waiter', subTypeValue: 'WAITER' },
        { subTypeName: 'House Keeping', subTypeValue: 'HOUSE_KEEPING' },
        { subTypeName: 'Laundry', subTypeValue: 'LAUNDRY' },
        { subTypeName: 'Others', subTypeValue: 'OTHERS' },

      ],
      category: [
        { categoryName: 'Transport', categoryValue: 'TRANSPORT' },
        { categoryName: 'Driver', categoryValue: 'DRIVER' },
        { categoryName: 'Music & DJ', categoryValue: 'MUSIC_AND_DJ' },
        { categoryName: 'Beautician', categoryValue: 'BEAUTICIAN' },
        { categoryName: 'Home Tutor', categoryValue: 'HOME_TUTOR' },
        { categoryName: 'Online Tutor', categoryValue: 'ONLINE_TUTOR' },
        { categoryName: 'Nurses & Attenders', categoryValue: 'NURSES_AND_ATTENDERS' },
        { categoryName: 'Online Doctor', categoryValue: 'ONLINE_DOCTOR' },
        { categoryName: 'Creche', categoryValue: 'CRECHE' },
        { categoryName: 'Cook', categoryValue: 'COOK' },
        { categoryName: 'Vet', categoryValue: 'VET' },
        { categoryName: 'Waiter', categoryValue: 'WAITER' },
        { categoryName: 'House Keeping', categoryValue: 'HOUSE_KEEPING' },
        { categoryName: 'Laundry', categoryValue: 'LAUNDRY' },
        { categoryName: 'Others', categoryValue: 'OTHERS' },
      ]
    },

  ];


  constructor() { }

  setUserDetails(user:any) {
    if (user) {
      localStorage.setItem('access_token', user.access_token);
      localStorage.setItem('user_type', user.user_type);
      localStorage.setItem('userId', user.user_id);
      localStorage.setItem('userName', user.user_name);
    }
  }

  updateDataChange(i:any) {
    this.dataChangeEvent.next(i);
  }

  get dataChangeSubscription() {
    return this.dataChangeEvent.asObservable();
  }

  updateTypeChangeForBanner(category:any) {
    this.typeChangeForBannerEvent.next(category);
  }

  get typeChangeForBannerSubscription() {
    return this.typeChangeForBannerEvent.asObservable();
  }

  unsubscribeCategoryChange() {
    this.categoryChangeEvent.unsubscribe();
  }

}
