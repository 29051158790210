<div class="loginBackground">
  <div class="container">
    <div class="m-5 p-5">
      <div class="row p-5">
        <div class="col-xs-12 col-lg-12 col-md-12 col-sm-12 col-12 tableShadow bg-white minHeight-60vh">


          <div class="row">
            <div class="col-xs-7 col-lg-7 col-md-7 col-sm-7 col-12">

              <div class="">
                <div class="text-center"></div>
                <div class="py-5 px-5">
                  <div class="pb-3 pt-5 text-center bold font-24 greyFont">
                    <span>{{'login.textLogo' | translate}}</span> <img alt="{{'login.imageAlt' | translate}}" src="../../assets/images/Bookmyloft logo.png"
                      class="logoSize ml-2" style="margin-top: -14px;">
                  </div>
                  <div class="py-4">
                    <form [formGroup]="loginForm" (ngSubmit)="onClickLogin()">
                      <div class="text-info small"><sup class="font-red"></sup>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <input class="loginInput px-2 font-14 light w-100 my-1" placeholder="{{'login.placeholderEmail' | translate}}" id='email'
                              formControlName="email" type="text" maxlength="50">
                            <div class="error loginError"
                              *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].dirty">
                              <span *ngIf="loginForm.controls['email'].errors?.['required']">{{'login.emailTextRequired' | translate}}</span>
                              <span
                                *ngIf="loginForm.controls['email'].errors?.['email'] && !loginForm.controls['email'].errors?.['maxlength'] && !loginForm.controls['email'].errors?.['required']">{{'login.emailFormatText' | translate}}</span>
                            </div>
                          </div>
                          <div class="col-md-12 my-2 text-center">
                            <input placeholder="{{'login.placeholderPassword' | translate}}" maxlength="20" type="password"
                              class="loginInput px-2 font-14 light w-100 my-1" id='password ' name="password"
                              formControlName="password">
                            <div class="error loginError"
                              *ngIf="loginForm.controls['password'].invalid &&loginForm.controls['password'].dirty">
                              <span *ngIf="loginForm.controls['password'].errors?.['required']">{{'login.passwordTextRequired' | translate}}</span>
                              <span *ngIf="loginForm.controls['password'].errors?.['minlength']">
                               {{'login.passwordLengthText' | translate}} </span>
                            </div>
                          </div>
                          
                        </div>
                      </div>

                      <div class="row pb-4">
                        <div class="col-md-12 text-center">
                          <button class="border-0 text-white redBG pointer font-14 light py-1 px-5"
                            type="submit">{{'login.buttonText' | translate}}</button> </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-xs-5 col-lg-5 col-md-5 col-sm-5 col-12 redBG">

              <div class="text-center px-3 pad-top-btm-55">
                <h2 class="text-white Bold font-24">{{'login.greet' | translate}}!</h2>
                <div class="font-16 text-white light">{{'login.loginMessage' | translate }}</div>
               
              </div>
              <div class="text-right w-100 pb-4 pt-3 pointer">
                <a (click)="openForgotPasswordModel(forgotPasswordTemplate)" class="font-12 light pointer text-white">{{'login.forgotPassword' | translate}}
                  ?</a>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>



<!--Forgot Password Modal-->

<ng-template #forgotPasswordTemplate>
  <form [formGroup]="forgotPasswordForm">
    <div class="modal-body">
      <button type="button" class="close pull-right text-white font-16" aria-label="Close" (click)="clearEmailField()">
        <span aria-hidden="true">&times;</span>
    </button>
      <div class="row">
        <div class="col-md-12 font-14 text-center">
          <input class="loginInput px-2 font-14 light w-100 my-1" type="text" placeholder="{{'forgotPassword.placeholderText' | translate}}"
            formControlName="emailAddress">

          <div *ngIf="
          forgotPasswordForm.controls['emailAddress'].invalid &&
          forgotPasswordForm.controls['emailAddress'].dirty
                ">
            <span class="error text-white" *ngIf="forgotPasswordForm.controls['emailAddress'].errors?.['required']">{{'forgotPassword.emailRequiredMessage' | translate}}</span>
            <span class="error text-white" *ngIf="
            forgotPasswordForm.controls['emailAddress'].errors?.['email'] &&
                  !forgotPasswordForm.controls['emailAddress'].errors?.['required']
                  ">{{'forgotPassword.emailFormatText' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-md-12 text-center">
        <button class="border-0 text-white bg-dark pointer font-14 light py-1 px-3 forgotPaswrdBtn" type="submit"
          (click)="onClickResetPassword(resetPasswordTemplate)">{{'forgotPassword.buttonText'| translate }}</button> </div>
    </div>
  </form>
</ng-template>




<!-- Reset password model -->

<ng-template #resetPasswordTemplate>
  <div class="modal-body">
    <span class="modal-title pull-left">{{'resetPassword.templateText' | translate}}</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="resetForm()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div *ngIf= "showMessage" class="txtColor">
      <h3>{{message}}</h3>
      </div>
    <form [formGroup]="resetPasswordForm">
    <div class="font-12 regular py-1">{{'resetPassword.otp' | translate}}</div>
    <div>
      <input type="text" formControlName="otp"
          class="productEditInput w-100 font-14 light px-2"
          placeholder="{{'resetPassword.otpPlaceholder' | translate}}">
      <div
          *ngIf="resetPasswordForm.controls['otp'].invalid && resetPasswordForm.controls['otp'].touched">
  <span class="error text-white"
              *ngIf="resetPasswordForm.controls['otp'].hasError('required')  && resetPasswordForm.controls['otp'].touched">
              {{'resetPassword.otpRequired' | translate}}</span>
          <span class="error text-white"
              *ngIf="resetPasswordForm.controls['otp'].errors?.['maxlength'] && resetPasswordForm.controls['otp'].touched">
   {{'resetPassword.otpLengthValidation' | translate}} </span>
              <span class="error text-white" *ngIf="
              !resetPasswordForm.controls['otp'].hasError('required') &&
              resetPasswordForm.controls['otp'].hasError('pattern')">{{'resetPassword.otpNumberCheck' | translate}}
              </span>
      </div>
  </div>
  <div class="font-12 regular py-1">{{'resetPassword.passwordText' | translate}}</div>
  <div>
      <input type="password" formControlName="password" maxlength="20"
          class="productEditInput w-100 font-14 light px-2"
          placeholder="{{'resetPassword.passwordPlaceholder' | translate}}">
      <div
          *ngIf="resetPasswordForm.controls['password'].invalid && resetPasswordForm.controls['password'].touched">
          <span class="error text-white"
              *ngIf="resetPasswordForm.controls['password'].hasError('required')  && resetPasswordForm.controls['password'].touched ">
              {{'resetPassword.passwordTextRequired' | translate}}</span>
          <span class="error text-white"
              *ngIf="resetPasswordForm.controls['password'].errors?.['minlength'] && resetPasswordForm.controls['password'].touched">
              {{'resetPassword.passwordLengthText' | translate}}</span>
      </div>
  </div>
  <div class="font-12 regular py-1">{{'resetPassword.confirmPasswordText' | translate}}</div>
  <div>
      <input type="password" formControlName="confirmPassword" maxlength="20"
          class="productEditInput w-100 font-14 light px-2"
          placeholder="{{'resetPassword.confirmPassworPlaceholder' | translate}}">
      <div
          *ngIf="resetPasswordForm.controls['confirmPassword'].invalid && resetPasswordForm.controls['confirmPassword'].touched">
          <span class="error text-white"
              *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required') && resetPasswordForm.controls['confirmPassword'].touched">Confirm
              {{'resetPassword.confirmPasswordTextRequired' | translate}}</span>
          <span class="error text-white"
              *ngIf="resetPasswordForm.controls['confirmPassword'].errors?.['minlength'] && resetPasswordForm.controls['confirmPassword'].touched">
              {{'resetPassword.confirmPasswordLengthText' | translate}}</span>
          <span class="error text-white"
              *ngIf="resetPasswordForm.controls['confirmPassword'].errors?.['match']">
              {{'resetPassword.passwordMatch' | translate}}</span>
      </div>
  </div>
  </form>
  </div>
  <div class="modal-footer">
    <button class="font-12 light cancelBtn pointer px-4 bg-transparent popupBtn" type="submit"
        (click)="resetForm()">{{'resetPassword.cancelButton' | translate}}</button>

    <button class="text-white font-12 light border-0 pointer popupBtn bg-dark" type="submit"
    (click)="onPasswordReset()">{{'resetPassword.SubmitButton' | translate}}</button>
</div>
</ng-template>



