import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { RestService } from '../core/rest.service';
import { IExperienceDetails } from '../Interfaces/experience.interface';
import { IRestResponse } from '../Interfaces/rest-response.interface';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {

  constructor(private restService: RestService) { }

  public searchExperience(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/experience/home', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }

  public searchExperienceCities(data:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/experience/cities', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
  public getExperienceById(experienceId: string): Observable<IRestResponse<IExperienceDetails>> {
    return this.restService.get(Environment.UrlPath + `/experience/${experienceId}`, null).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
  public getExperienceLocality(city: string): Observable<any> {
    return this.restService.get(Environment.UrlPath + `/master/locality/hostsearch/${city}?listingType=EXPERIENCE_PROVIDER`, null).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
}
