import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { RestService } from '../core/rest.service';
import { IFoodAndBeverages } from '../Interfaces/food-beverages.interface';
import { IRestResponse } from '../Interfaces/rest-response.interface';

@Injectable({
  providedIn: 'root'
})
export class FoodBeveragesService {

  constructor(private restService: RestService) { }

  public searchByMenuItem(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/foodAndBeverage/menuItems', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }

  public searchFoodBeverages(data:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/foodAndBeverage/home', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
  public getFoodBeverages(data:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/foodAndBeverage', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
  public searchPropertyCities(data:any): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/property/cities', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
  public getFoodAndBeveragesById(foodAndBeveragesId: string): Observable<IRestResponse<IFoodAndBeverages>> {
    return this.restService.get(Environment.UrlPath + `/foodAndBeverage/${foodAndBeveragesId}`, null).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
}
