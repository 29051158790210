import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { RestService } from '../core/rest.service';
import { IPropertyDetails } from '../Interfaces/property.interface';
import { IRestResponse } from '../Interfaces/rest-response.interface';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private restService: RestService) { }

  public searchProperty(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/property/home', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }

  public searchPropertyCities(data:any): Observable<any> {
    return this.restService.post(Environment.UrlPath + '/property/cities', data, true).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
  public getPropertyById(propertyId: string): Observable<IRestResponse<IPropertyDetails>> {
    return this.restService.get(Environment.UrlPath + `/property/${propertyId}`, null).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
}
