<header *ngIf="auth.isLoggedIn">

  <nav class="navbar navbar-expand-lg bg-white position-relative">
    <div class="container mx-auto">
      <a class="navbar-brand nav-logo bg-white d-inline-block p-2" href="#">
        <img alt="logo" src="../../../assets/images/bml-logo.png" class="logo" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item pointer" routerLink="/listing" [ngClass]="{ active:checkPageUrl('/listing')}">
            <a class="nav-link px-4 text-dark bold py-1">{{'menuList.listing' | translate}}</a>
          </li>
          <li class="nav-item pointer" routerLink="/user/manage" [ngClass]="{ active:checkPageUrl('/user') }">
            <a class="nav-link px-4 text-dark bold py-1">{{'menuList.user' | translate}}</a>
          </li>
          <li class="nav-item pointer" routerLink="/vendor/manage" [ngClass]="{ active:checkPageUrl('/vendor')}">
            <a class="nav-link px-4 text-dark bold py-1">{{'menuList.vendor' | translate}}</a>
          </li>
          <li class="nav-item pointer" routerLink="/booking/manage" [ngClass]="{ active:checkPageUrl('/booking') }">
            <a class="nav-link px-4 text-dark bold py-1">{{'menuList.bookings' | translate}}</a>
          </li>
          <!-- <li class="nav-item pointer" routerLink="/banner" [ngClass]="{ active:checkPageUrl('/banner') }">
            <a class="nav-link px-4 text-dark bold py-1">{{'menuList.banner' | translate}}</a>
          </li> -->
          <li class="nav-item pointer" routerLink="/coupon/list" [ngClass]="{ active: checkPageUrl('/coupon') }">
            <a class="nav-link px-4 text-dark bold py-1">{{'menuList.coupon' | translate}}</a>
          </li>
          <li class="nav-item pointer" routerLink="/referral" [ngClass]="{ active: checkPageUrl('/referral') }">
            <a class="nav-link px-4 text-dark bold py-1">{{'menuList.referral' | translate}}</a>
          </li>
        </ul>
        <div class="d-flex">
          <a class="navbar-brand admin-logout fw-bold font-16 greyFont">
            Welcome Admin
            <img alt="Logout" src="../../../assets/images/logout.svg" (click)="onClickLogout()" title="Logout"
              id="logout" class="ml-3 logoutIcon pointer" />
          </a>
        </div>
      </div>
    </div>
  </nav>

</header>
<div style="height: 100px;"></div>
