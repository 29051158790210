import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services';
import { CustomValidators } from '../validators/custom.validator';
import { Match } from '../validators/match.validator';
import { validateAllFormFields } from '../utils/validateform';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  newPassword: string='';
  oldPassword: string='';
  loginForm!: FormGroup;
  userId: string='';

  constructor(private formBuilder: FormBuilder, private authService: AuthService) { }

  ngOnInit(): void {
    this.onInitForm();
  }

  onInitForm() {
    this.loginForm = this.formBuilder.group({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(25),
        CustomValidators.oneDigitValidator,
        CustomValidators.oneSpecialCharValidator,
        CustomValidators.uppercaseValidator
      ]),
      OldPassword: new FormControl('', [
        Validators.required,

      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
      ])
    },
      {
        validator: Match('newPassword', 'confirmPassword')
      });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const data = {
        newPassword: this.loginForm.value.newPassword,
        oldPassword: this.loginForm.value.OldPassword
      };
      this.authService.changePassword(data).subscribe(res => {
      });
    } else {
      validateAllFormFields(this.loginForm);
    }
  }
}
