import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { RestService } from '../core/rest.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private restService: RestService) { }

  public addCoupon(data:any): Observable<any> {
    return this.restService
      .post(Environment.UrlPath + `/coupon`, data).pipe(
        map(res => res),
        catchError(error => throwError(error))
      );
  }

  public editCoupon(couponId:any, data:any): Observable<any> {
    return this.restService
      .put(Environment.UrlPath + `/coupon/${couponId}`, data).pipe(
        map(res => res),
        catchError(error => throwError(error))
      );
  }

  public getCouponList(data:any): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/coupon`, data).pipe(
        map(res => res),
        catchError(error => throwError(error))
      );
  }

  public getCoupon(couponId:any): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/coupon/${couponId}`, null).pipe(
        map(res => res),
        catchError(error => throwError(error))
      );
  }
}
