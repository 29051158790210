import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { CouponRoutingModule } from './coupon-routing.module';
import { CouponComponent } from './coupon/coupon.component';
import { SharedModule } from '../shared/shared.module';
import { CouponListComponent } from './coupon-list/coupon-list.component';
import { CouponDetailComponent } from './coupon-detail/coupon-detail.component';
import { PipeModule } from '../pipes/pipe.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [CouponComponent, CouponListComponent, CouponDetailComponent],
  imports: [
    CommonModule,
    CouponRoutingModule,
    SharedModule,
    PipeModule,
    TranslateModule
  ],
  providers: [DatePipe]
})
export class CouponModule { }
