import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth.guard';
import { CouponDetailComponent } from './coupon-detail/coupon-detail.component';
import { CouponListComponent } from './coupon-list/coupon-list.component';
import { CouponComponent } from './coupon/coupon.component';


const routes: Routes = [
  { path: 'list', component: CouponListComponent, canActivate: [AuthGuard] },
  { path: 'new', component: CouponComponent, canActivate: [AuthGuard] },
  { path: 'coupon-detail', component: CouponDetailComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CouponRoutingModule { }
