import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICoupon } from 'src/app/Interfaces/coupon.interface';
import { IPaginationResponse } from 'src/app/Interfaces/pageable-rest-response.interface';
import { IRestResponse } from 'src/app/Interfaces/rest-response.interface';
import { CouponService } from 'src/app/services/coupon.service';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss']
})
export class CouponListComponent implements OnInit {

  pageNumber = 1;
  pageSize = 20;
  pager = 10;
  totalElements = 0;
  coupons: ICoupon[] = [];

  constructor(
    private router: Router,
    private couponService: CouponService
  ) { }

  ngOnInit(): void {
    this.getCoupons();
  }

  getCoupons() {
    const data = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }
    this.couponService.getCouponList(data).subscribe((res: IRestResponse<IPaginationResponse<ICoupon[]>>) => {
     if (res.response) {
      this.coupons = res.response.content;
      this.totalElements = res.response.totalElements;
     }
     
    });
  }

  couponView(couponId:any) {
    this.router.navigate(['/coupon/coupon-detail'], { queryParams: { id: couponId}});
  }

  couponEdit(couponId:any) {
    this.router.navigate(['/coupon/new'], { queryParams: { id: couponId } });
  }

  pageChanged(event:any) {
    this.pageNumber = event.page;
    this.getCoupons();
  }

  onClickAddCoupon() {
    this.router.navigate(['/coupon/new']);
  }

}
