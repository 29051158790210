import { NgModule } from '@angular/core';
import { DragDropDirective } from '../directives/dragdrop.directive';
import { TextUpperCaseDirective } from '../directives/textcapitalize.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PipeModule } from '../pipes/pipe.module';

@NgModule({
  declarations: [
    TextUpperCaseDirective,
     DragDropDirective
    ],
  imports: [
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PaginationModule,
    PopoverModule,
    BsDatepickerModule,
  ],
  exports: [
    // DragDropDirective,
    // TextUpperCaseDirective,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PaginationModule,
    PopoverModule,
    BsDatepickerModule,
    PipeModule
  ],
  providers: [],
})
export class SharedModule { }


