import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {

  canActivate():any {
      if (localStorage.getItem('user_type')) {
        return true;
      }
  }
}
