import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateAndTime' })
export class DateAndTimePipe implements PipeTransform {
  transform(date: Date | string): any {
    date = new Date(date);  // if original type was a string
    return new DatePipe('en-US').transform(date, 'd/M/yyyy, h:mm a');
}
}
