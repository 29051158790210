import { NgModule, ModuleWithProviders } from '@angular/core';
import { LoaderService } from '../core/loader/loader.service';
import { MessageService } from '../core/message/message.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { DataService } from './data.service';
import { RestService } from '../core/rest.service';
import { BehaviorSubject } from 'rxjs';
import { FoodBeveragesService } from './food-beverages.service';

// to share single instance of services across all modules and components
@NgModule({})
export class SharedServiceModule {

  private message = new BehaviorSubject('false'); // initially setting false as login
  loginMessage = this.message.asObservable();

  static forRoot(): ModuleWithProviders<SharedServiceModule> {
    return {
      ngModule: SharedServiceModule,
      providers: [RestService, LoaderService, MessageService, AuthService,
        UserService, DataService, FoodBeveragesService]
    };
  }

  nextLoginMessage(message: string) {
    this.message.next(message);
  }
}
