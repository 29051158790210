import { Environment } from 'src/environments/environment';
import { Injectable, Compiler } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestService } from '../core/rest.service';
import { map, catchError } from 'rxjs/operators';
import { DataService } from './data.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private restService: RestService,
              private compiler: Compiler,
              private dataService: DataService,
              private router: Router
    ) { }
  public login(data:any): Observable<{}> {
    return this.restService
      .oauthLogin(Environment.baseUrl + `/oauth/token`, data, false).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public logout(): Observable<{}> {
    return this.restService
      .oauthLogout(Environment.baseUrl + `/oauth/logout`, null, true).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public forgotPassword(registeredEmail:any): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + '/user/forgotPassword/' + registeredEmail, null).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  resetPassword(data:any) {
    return this.restService.put(Environment.UrlPath + '/user/forgotPassword', data).pipe(
      map(res => res),
      catchError((error) =>
        throwError(error)));
  }

  getForgotPasswordEmailLink(data:any) {
    return this.restService
      .post(
        Environment.baseUrl + `/adresnow/user/forgotpassword`, data, false
      )
      .pipe(map((res: any) => res));
  }
  onSettingPassword(data:any, email:any): Observable<any> {
    return this.restService.
      put(Environment.UrlPath + '/auth/setPassword/' + email, data).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public forgotpassword(emailAddress:any): Observable<{}> {
    return this.restService
      .post(Environment.UrlPath + '/auth/forgotPassword?email=' + emailAddress, {}).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  changePassword(data:any) {
    return this.restService.put(Environment.UrlPath + '/user/changePassword', data).pipe(
      map(res => res),
      catchError((error) =>
        throwError(error)));
  }
  get isLoggedIn() {
     if (localStorage.getItem('user_type') !== null) {
      return true;
    }
    return false;
  }

  public forceLogout() {
    this.compiler.clearCache();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
