<div class="container">
  <div>
    <form [formGroup]="couponForm" (ngSubmit)="onSubmit()">

      <div class="row pb-3">
        <div class="col-12">
          <div class="me-5 float-start">
            <input type="radio" formControlName="couponType" value="MULTIPLE">
            <span class="font-18"> Common Coupon</span>
          </div>
          <div class="">
            <input type="radio" formControlName="couponType" value="SINGLE">
            <span class="font-18"> OneTime Coupon</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3 col-lg-2 col-md-4 col-sm-4">
          <h2>
            {{'coupon.expiryDate.name' | translate}}
          </h2>

        </div>
        <div class="col-1">
          <h2>
            : </h2>
        </div>
        <div class="col-6 col-lg-3 col-md-6 col-sm-6">
          <input type="text" formControlName="toDate" bsDatepicker
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', isAnimated: true, showWeekNumbers:false}"
            [dateCustomClasses]="dateCustomClasses" class="inputBox  px-2 filter ng-pristine ng-valid ng-touched">

          <div class="error">
            <div class="error" *ngIf="couponForm.get('toDate')?.invalid && couponForm.get('toDate')?.dirty">
              <span *ngIf="couponForm.get('toDate')?.errors?.['required']">
                {{'coupon.expiryDate.required' | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3 col-lg-2 col-md-4 col-sm-4">
          <h2>
            {{'coupon.minimumOrder.name' | translate}}
          </h2>
        </div>
        <div class="col-1">
          <h2>
            :
          </h2>
        </div>
        <div class="col-6 col-lg-3 col-md-6 col-sm-6">
          <input type="number" formControlName="minAmountToApply"
            class="inputBox  px-2 filter ng-pristine ng-valid ng-touched">

          <div class="error">
            <div class="error"
              *ngIf="couponForm.get('minAmountToApply')?.invalid && couponForm.get('minAmountToApply')?.dirty">
              <span *ngIf="couponForm.get('minAmountToApply')?.errors?.['required']">
                {{'coupon.minimumOrder.required' | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div formGroupName="discountDetails">
        <div class="row pb-3 ">
          <div class="col-3 col-lg-2 col-md-4 col-sm-4 ">
            <div class="pb-4">
              <input type="radio" formControlName="discountType" value="flat">
              <span class="font-18">

                {{'coupon.discount.flat.name' | translate}} </span>
            </div>
            <div>
              <input type="radio" formControlName="discountType" value="percentage">
              <span class="font-18">

                {{'coupon.discount.percentage.name' | translate}}</span>
            </div>
          </div>
          <div class="col-1">
            <h2 class="pt-2">
              :
            </h2>
          </div>
          <div class="col-6 col-lg-3 col-md-6 col-sm-6">
            <div class="pt-2">
              <div *ngIf="!isFlatDiscount">
                <input type="text" formControlName="discountPercentage"
                  class="inputBox px-2 filter ng-pristine ng-valid ng-touched">

                <div class="error" *ngIf="couponForm.get('discountDetails')?.get('discountPercentage')?.invalid &&
                                     couponForm.get('discountDetails')?.get('discountPercentage')?.dirty">
                  <span *ngIf="couponForm.get('discountDetails')?.get('discountPercentage')?.errors?.['required']">
                    {{'coupon.discount.required' | translate}}
                  </span>
                  <span *ngIf="!couponForm.get('discountDetails')?.get('discountPercentage')?.errors?.['required'] &&
                  couponForm.get('discountDetails')?.get('discountPercentage')?.errors?.['percentage']">
                    {{'coupon.discount.percentage.pattern' | translate}}
                  </span>
                </div>
              </div>
              <div *ngIf="isFlatDiscount">
                <input type="text" formControlName="directDiscount"
                  class="inputBox  px-2 filter ng-pristine ng-valid ng-touched">

                <div class="error" *ngIf="couponForm.get('discountDetails')?.get('directDiscount')?.invalid &&
                                     couponForm.get('discountDetails')?.get('directDiscount')?.dirty">
                  <span *ngIf="couponForm.get('discountDetails')?.get('directDiscount')?.errors?.['required']">
                    {{'coupon.discount.required' | translate}}
                  </span>
                  <span *ngIf="!couponForm.get('discountDetails')?.get('directDiscount')?.errors?.['required'] &&
                  couponForm.get('discountDetails')?.get('directDiscount')?.errors?.['pattern']">
                    {{'coupon.discount.flat.pattern' | translate}}
                  </span>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="row">
        <div class="col-3 col-lg-2 col-md-4 col-sm-4">
          <h2>
            {{'coupon.couponCode.name' | translate}} </h2>
        </div>
        <div class="col-1">
          <h2>
            : </h2>
        </div>
        <div class="col-6 col-lg-3 col-md-6 col-sm-6">
          <input type="text" formControlName="couponCode"
            class="inputBox px-2 filter ng-pristine ng-valid ng-touched">

          <div class="error" *ngIf="couponForm.get('couponCode')?.invalid && couponForm.get('couponCode')?.dirty">
            <span *ngIf="couponForm.get('couponCode')?.errors?.['required']">
              {{'coupon.couponCode.required' | translate}}
            </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3 col-lg-2 col-md-4 col-sm-4">
          <h2>
            {{'coupon.couponUsage.name' | translate}}
          </h2>
        </div>
        <div class="col-1">
          <h2>
            : </h2>
        </div>
        <div class="col-6 col-lg-3 col-md-6 col-sm-6">
          <input type="number" formControlName="numberOfTimes"
            class="inputBox px-2 filter ng-pristine ng-valid ng-touched">

          <div class="error" *ngIf="couponForm.get('numberOfTimes')?.invalid && couponForm.get('numberOfTimes')?.dirty">
            <span *ngIf="couponForm.get('numberOfTimes')?.errors?.['required']">
              {{'coupon.couponUsage.required' | translate}}
            </span>
          </div>
        </div>
      </div>

      <div class="row py-5">
        <div class="col-6 text-end">

          <button type="button" (click)="onClickCancel()"
            class="outline-0 font-22  border-0 px-4 py-1 bg-dark text-white me-5 ">Cancel</button>


          <button type="submit" class="outline-0 font-22  border-0 px-4 py-1 redBG text-white ">Submit</button>


        </div>
      </div>

    </form>
  </div>
</div>