import { Component } from '@angular/core';
import { SharedServiceModule } from './services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bookmyloft_admin';
  isMenu = false;

  constructor(private sharedService: SharedServiceModule,
    private translate: TranslateService,
    private router: Router) {
    translate.setDefaultLang('en');
    this.sharedService.loginMessage.subscribe((message) => {
      if (message === 'true') {
        this.isMenu = true;
      }
    });
  }
}
