import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { RestService } from './../core/rest.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(public restService: RestService) { }

  getUsers(name:any, email:any, mobileNumber:any, from:any, to:any, pageNumber:any) {

    return this.restService.get(Environment.UrlPath + '/admin/user?name=' + name + '&email=' + email + '&mobileNumber=' + mobileNumber + '&fromDate=' + from + '&toDate=' + to + '&pageNumber=' + pageNumber, false).pipe(map((res: any) => res));
  }

  unblockUser(userId:any) {
    return this.restService.post(Environment.UrlPath + '/admin/user/unblock/' + userId, false).pipe(map((res: any) => res));
  }

  blockUser(userId:any) {
    return this.restService.post(Environment.UrlPath + '/admin/user/block/' + userId, false).pipe(map((res: any) => res));
  }

  getUsersExcel() {
    return this.restService.get(Environment.UrlPath + '/admin/user/excelDownload', null, false, true).pipe(map(res => res));
  }
}
