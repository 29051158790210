import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public locationSearch = new Subject<any>();
  constructor(private location: Location,
    private router: Router,
    private route: ActivatedRoute,) { }

  public removeEmpty(obj:any) {
    return Object.assign(
      {},
      ...Object.keys(obj)
        .filter((el) => obj[el] !== undefined && obj[el] !== '' && obj[el] !== null)
        .map((el) => {
          return obj[el] instanceof Array && obj[el].length === 0 ? 0 : ({ [el]: obj[el] });
        })
    );
  }

  // function to check if the object value exists and return '' instead of the value. to reduce code
  public ifExists(obj:any) {
    return obj ? obj : '';
  }

  public goBack() {
    this.location.back();
  }
  public paramChange(paramValue: any): void {
    const queryParams: Params = paramValue;
    if (Object.keys(queryParams).length !== 0) {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge'
        });
    } else {
      this.router.navigate([], { replaceUrl: true });
    }
  }
}
